

$('#menu-button').click(function(e) {
    e.preventDefault();

    $('header nav').addClass('show');
});

$('#close-menu').click(function(e) {
    e.preventDefault();

    $('header nav').removeClass('show');
});
